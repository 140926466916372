import * as React from 'react';
import './TagsControls.css';
import {useMemo, useState} from 'react';
import cx from 'classnames';
import {getDefaultTags} from '../common/getDefaultTags';

export type TagsControlsProps = {
  selectedTags: string[];
  onToggleTag: (tag: string)=> void;
};

export const TagsControls = ({selectedTags, onToggleTag}: TagsControlsProps): React.ReactElement => {
  const tags = useMemo(()=> getDefaultTags(), []);
  const [showMore, setShowMore] = useState(false);

  return <section
    data-bs-version="5.1"
    className="content11 cid-tOyGwsfN4w"
    id="content11-8"
    style={{ paddingBottom: "3rem" }}
  >
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-10">
          <div className={cx('TagsControls__container mbr-section-btn align-center', {
            'TagsControls__container-show-more': showMore,
          })}>
            {
              tags.map((tag, index)=>
                <button
                  key={tag}
                  className={cx('TagButton btn btn-primary display-4 btn-sm', {
                    'btn-primary-outline': !selectedTags.includes(tag),
                    'TagButton__more': index > 5,
                  })}
                  onClick={()=> onToggleTag(tag)}
                >{tag.replace('_', ' ')}</button>
              )
            }
            <button className="TagButton TagButton__more-button btn btn-primary display-4 btn-sm btn-primary-outline" onClick={()=> setShowMore(true)}>+ more</button>
          </div>
        </div>
      </div>
    </div>
  </section>;
};
