import * as React from 'react';
import './PictureList.css';
import {LoadMoreItemsCallback, Masonry, RenderComponentProps, useInfiniteLoader} from 'masonic';
import {useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {SortDirection} from '../SortControl/SortControl';

const BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:8000' : '';

type Item = {
  id: string;
  picture: string;
  width: string;
  height: string;
  created_date: string;
};

const Card = (props: RenderComponentProps<Item>) : React.ReactElement => {
  const nativeAdHeight = 1603;
  const backgroundColor = useMemo(()=> getRandomItem([
    'rgba(0, 0, 0, .05)',
    'rgba(0, 0, 0, .1)',
    'rgba(0, 0, 0, .15)',
  ]), []);
  const {imageHeight, adHeight} = useMemo(()=> {
    const ratio = props.width / parseInt(props.data.width);
    return {
      imageHeight: parseInt(props.data.height) * ratio,
      adHeight:  nativeAdHeight * ratio,
    };
  }, [props.width, props.data.width]);

  const imageUrl = `${BASE_URL}/data/upload/${props.data.picture}`;
  const onClickImage = async (e: React.MouseEvent<HTMLAnchorElement>)=> {
    e.preventDefault();
    const detailUrl = `${BASE_URL}/detail/?id=${props.data.id}`;
    window.open(detailUrl, '_blank');
    await axios.post(`${BASE_URL}/api/public/openimage?id=${props.data.id}`);
  };
  return <div key={props.data.id}>
    <div className="card" style={{height: imageHeight, backgroundColor}}>
      <a target="_blank" onClick={onClickImage}>
        <img className="image" height={imageHeight} src={imageUrl}/>
      </a>
    </div>
    {
      props.index % 40 === 4 && <div className="card PulseWave" style={{height: adHeight, backgroundColor: 'rgba(0, 0, 0, .15)'}}>
        <a href="https://www.pulsewaveapp.com" target="_blank">
          <img className="image" height={adHeight} src={'/assets/images/pulsewaveapp.png'}/>
        </a>
      </div>
    }
  </div>;
};

export type PictureListProps = {
  tags: string[];
  sortDirection: SortDirection;
};

export const PictureList = ({tags, sortDirection}: PictureListProps): React.ReactElement => {
  const maxItemsRef = useRef(0);
  const randomSeed = useMemo(()=> Date.now(), []);
  const [items, setItems] = useState<Item[]>([]);
  const fetchMoreItems : LoadMoreItemsCallback<Item> = async (startIndex, stopIndex, currentItems) => {
    maxItemsRef.current = stopIndex;
    const tagsPart = tags.map(tag => `&${tag}=true`).join('');
    const sortPart = sortDirection === 'random' ? `&sort=random&randomSeed=${randomSeed}` : `&sort=${sortDirection}`;
    const nextItemsResponse = await axios.get(
      `${BASE_URL}/api/public/posts?startIndex=${startIndex}&count=${(stopIndex - startIndex) + 1}${tagsPart}${sortPart}`,
    );
    const nextItems : Item[] = nextItemsResponse.data.entry;

    setItems([...currentItems, ...nextItems]);
  };

  useEffect(()=> {
    fetchMoreItems(0, 20, []);
  }, []);

  // @ts-ignore
  const maybeLoadMore = useInfiniteLoader(fetchMoreItems, {
    isItemLoaded: (index, items) => {
      return index <= maxItemsRef.current;
    },
  });

  return <div className="PictureList__container">
    <Masonry key="masonry" render={Card} items={items} onRender={maybeLoadMore} columnWidth={300} columnGutter={16} rowGutter={32} />
  </div>;
};

function getRandomItem<T>(items: T[]): T {
  if (items.length === 0) {
    throw new Error('The array is empty');
  }

  const randomIndex = Math.floor(Math.random() * items.length);
  return items[randomIndex];
}
