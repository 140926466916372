import React, {useMemo, useState} from 'react';
import './App.css';
import {PictureList} from './PictureList/PictureList';
import {TagsControls} from './TagsControls/TagsControls';
import {SortControl, SortDirection} from './SortControl/SortControl';

function App() {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirection>('random');
  const [showPictures, setShowPictures] = useState(true);

  const forceReRenderPictures = (callback: ()=> void)=> {
    setShowPictures(false);
    setTimeout(()=> {
      setShowPictures(true);
      callback();
    }, 50);
  };

  const onToggleTag = (tag: string) => {
    forceReRenderPictures(()=> {
      if (selectedTags.includes(tag)) {
        const index = selectedTags.indexOf(tag);
        setSelectedTags([
          ...selectedTags.slice(0, index),
          ...selectedTags.slice(index + 1),
        ]);
      } else {
        setSelectedTags([
          ...selectedTags,
          tag,
        ]);
      }
    });
  };

  const onChangeSort = (direction: SortDirection)=> {
    forceReRenderPictures(()=> {
      setSortDirection(direction);
    });
  };

  return (
    <div className="app">
      <TagsControls selectedTags={selectedTags} onToggleTag={onToggleTag}/>
      <SortControl direction={sortDirection} onChangeSort={onChangeSort}/>
      {showPictures && <PictureList tags={selectedTags} sortDirection={sortDirection}/>}
    </div>
  );
}

export default App;
