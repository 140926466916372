export const getDefaultTags = () : string[] =>
  [
    'city',
    'village',
    'town',
    'farm',
    'shops',
    'hilltop',
    'sunny',
    'solar_panels',
    'water_wheel',
    'bikes',
    'airship',
    'train',
    'streetcar',
    'balloons',
    'ferris_wheel',
    'greenhouse',
    'dome',
    'gardens',
    'park',
    'bridge',
    'roller_coaster',
    'flowers',
  ];
