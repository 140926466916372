import * as React from 'react';
import './SortControl.css';

export type SortDirection = 'new' | 'popular' | 'random';

export type SortControlProps = {
  direction: SortDirection;
  onChangeSort: (direction: SortDirection)=> void;
};

export const SortControl = ({direction, onChangeSort: onChangeCallback}: SortControlProps): React.ReactElement => {

  const onChangeSort = (e: React.ChangeEvent<HTMLSelectElement>)=> {
    onChangeCallback(e.target.value as SortDirection);
  };

  return <div className="SortControl">
    <div className="justify-content-end flex-row align-items-center" style={{display: 'flex'}}>
      <div className="SortLabel">Sort by</div>
      <select className="SortSelect" value={direction} onChange={onChangeSort}>
        <option value="random">Random</option>
        <option value="popular">Popular</option>
        <option value="new">Newest</option>
      </select>
    </div>
  </div>;
};
